@import "./variables.scss";

.master-table-container {
  display: flex;
  tr {
    height: 42px;
  }
}

.usage-entrant-table {
  width: 30% !important;
  display: inline-table;
  margin-bottom: 10px !important;
}

.usage-table-container {
  overflow-y: scroll;
  width: 70% !important;
  display: inline-block;
}

#root > div > div.ui.container.nba-cont > div > div.ui.bottom.attached.segment.active.tab.nba-content > div > div.master-table-container > table.usage-entrant-table > thead > tr > th:nth-child(2) {
  width: 30% !important;
}

td.logo-cell, th.logo-cell {
  position: relative !important;
  display: flex !important;
  text-align: center;
  justify-content: center !important;
  img.ui.image {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    &.nba-standing-box {
      left: 0 !important;
      top: 3px;
    }
    &.mlb-standing-box {
      width: 100%;
      padding-right: 20%;
      margin-left: -30%;
      &.mlb-WSH-logo {
        max-height: 55px;
        height: 55px;
        top: -7px;
      }
      &:not(.mlb-WSH-logo) {
        top: 0px;
        max-height: 40px !important;
      }
    }
  }
}

td.week-cell {
  background-color: #A46743;
  color: white;
  font-weight: 600;
  text-align: center !important;
}

.stat-wrapper {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid var(--color-main);
}

.drop-wrapper {
  display: inline;
}

.header-note {
  display: inline;
  float: right;
  font-weight: 600;
  font-size: 14px;
  padding-top: 10px;
  i {
    margin: 0 10px;
    color: var(--color-main);
  }
}

.view-filter {
  background-color: var(--color-main) !important;
  i {
    color: #DCDCDB;
    font-weight: 600;
  }
  div.text {
    color: white;
  }
  div.menu.visible {
    background-color: var(--color-main) !important;
    span.text {
      color: white;
    }
  }
}

.view-filter-alt {
  background-color: #FABC2E !important;
  i {
    color: white;
    font-weight: 600;
  }
  div.text {
    color: white;
  }
  div.menu.visible {
    background-color: #FABC2E !important;
    span.text {
      color: white;
    }
  }
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.by-numbers {
  color: var(--color-main);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  padding-left: 4.5vw !important;
}

.pointer-arrow {
  margin: 5px 5px 5px 0 !important;
}

.ui.table:not(.unstackable) tr>td.lives-td {
  padding: .25em .25em !important;
  white-space: nowrap !important;
}

.activeUser {
  background-color: var(--color-main) !important;
  color: white;
  font-weight: 800;
}

.secondaryUser {
  background-color: var(--color-light) !important;
  color: white;
  font-weight: 800;
}
